// PlaybookTagsTable.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box,
  Paper, TablePagination, TextField, InputAdornment, IconButton, TableSortLabel,
  Button, Accordion, AccordionSummary, AccordionDetails, Typography, Modal, Select, MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import axiosInstance from '../api/axiosInstance';
import AddTagModal from '../components/AddTagModal';
import ContainerPageHeader from '../components/ContainerPageHeader';

const PlaybookTagsPage = ({ organisationId, teamId, selectOffensePlaybook, selectDefensePlaybook, selectSpecialPlaybook }) => {
  const { unit, type } = useParams(); 
  const [openModal, setOpenModal] = useState(false); 
  const [editTag, setEditTag] = useState(null);

  const [editPlaybook, setEditPlaybook] = useState(null);
  const [playbookTags, setPlaybookTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [playbooks, setPlaybooks] = useState([]);
  const [filteredPlaybooks, setFilteredPlaybooks] = useState([]);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name'); // Default-Sortierung nach Tag-Name
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const navigate = useNavigate();

  const [firstTypeName, setFirstTypeName] = useState('');

  // Fetch Playbook Tags based on the unit (Offense, Defense, Special)
  useEffect(() => {
    loadAllPlaybooks();
  }, [unit, type, organisationId, teamId]);

  const loadAllPlaybooks = async () => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${organisationId}/${teamId}/playbooks/?unit=${unit}`);
      console.log("API Response:", response.data); // Debugging: Zeigt die API-Antwort
      setPlaybooks(response.data);
      setFilteredPlaybooks(response.data);
    } catch (error) {
      console.error("Error fetching playbooks:", error);
    }
  };

  const handleSettingsClick = () => {
    // Navigiert zur PlaybookType-Seite
    navigate(`/terminology/types/${unit}`);
  };

  // Handle Pagination
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle Search
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
    if (value === '') {
      setFilteredPlaybooks(playbooks);
    } else {
      setFilteredPlaybooks(playbooks.filter(p =>
        p.name.toLowerCase().includes(value) ||
        p.description.toLowerCase().includes(value)
      ));
    }
  };

  // Sortier-Logik
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...filteredPlaybooks].sort((a, b) => {
      if (a[property] < b[property]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredPlaybooks(sortedData);
  };

  const handleAddOrEditPlaybook = async (tagData) => {
    try {
      if (editPlaybook) {
        // Wenn wir im Bearbeitungsmodus sind
        await axiosInstance.put(`/playbook_management/${organisationId}/${teamId}/playbooks/${editTag.id}/`, {
          ...tagData,
          organisation: organisationId,
          team: teamId,
          unit
        });
      } else {
        // Neuen Tag erstellen
        await axiosInstance.post(`/playbook_management/${organisationId}/${teamId}/playbooks/`, {
          ...tagData,
          organisation: organisationId,
          team: teamId,
          unit
        });
      }
      loadAllPlaybooks(); // Tags neu laden
      setOpenModal(false);
    } catch (error) {
      console.error('Error adding/editing tag:', error);
    }
  };


  // Edit Tag Handling
  const handleEditPlaybook = (playbook) => {
    setEditPlaybook(playbook);
    setOpenModal(true);
  };

  // Delete Tag Handling
  const handleDeletePlaybook = async (playbookId) => {
    try {
      await axiosInstance.delete(`/playbook_management/${organisationId}/${teamId}/playbooks/${playbookId}/`);
      loadAllPlaybooks(); // Nach erfolgreichem Löschen die Liste neu laden
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const mapStatusToText = (status) => {
    return status ? "Aktiv" : "Nicht aktiv";
  };
  

  const handleToggleStatus = async (tagData, currentStatus) => {
    const newStatus = currentStatus === true ? false : true; // Status umkehren
    try {
      await axiosInstance.put(`/playbook_management/${organisationId}/${teamId}/playbooks/${tagData.id}/`, {
        name: tagData.name,
        description: tagData.description,
        active: newStatus,
        organisation: organisationId,
        team: teamId,
        unit,
      });
      loadAllPlaybooks(); // Daten neu laden
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  return (
    <>
      <ContainerPageHeader title={unit ? `${unit} Playbooks` : `Playbooks`}>
        <TextField
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />} 
          onClick={() => {
            setEditPlaybook(null);  // Resetten von `editTag`, damit klar ist, dass wir einen neuen Tag erstellen
            setOpenModal(true);
          }}
        >
          Add Playbook
        </Button>

      </ContainerPageHeader>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, padding: 2 }}>
        {filteredPlaybooks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((playbook, index) => (
          <Box
            key={index}
            className="playbook-card"
            sx={{
              width: '300px',
              padding: '16px',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'scale(1.03)',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {playbook.name}
            </Typography>
            <Typography variant="body2" sx={{ color: '#757575', marginBottom: 1 }}>
              {playbook.description || 'No description provided.'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: playbook.active ? '#4caf50' : '#f44336',
                fontWeight: 'bold',
              }}
            >
              {playbook.active ? 'Active' : 'Inactive'}
            </Typography>
            <Typography variant="caption" sx={{ display: 'block', marginTop: 1 }}>
              Created At: {new Date(playbook.created_at).toLocaleString()}
            </Typography>
            <Typography variant="caption">
              Updated At: {new Date(playbook.updated_at).toLocaleString()}
            </Typography>

            {/* Accordion for Details */}
            <Accordion sx={{ marginTop: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <strong>Description:</strong> {playbook.description || 'No description available.'}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  <strong>Status:</strong> {playbook.active ? 'Active' : 'Inactive'}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredPlaybooks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PlaybookTagsPage;
